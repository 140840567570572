/* eslint-disable jsx-a11y/anchor-is-valid */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest } from '../../services'
import { GalleryDrawer } from '../Drawer/GalleryDrawer'

export default function FormalEdit() {
  const { setLoading } = useContext(LoadingContext)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState({
    title: '',
    short_description: '',
    target_amount: '',
    start_date: '',
    due_date: '',
    status: '',
    goal_type: '',
    sorting: -1,
  })
  const [organisation, setOrganisation] = useState<any>()
  const [bannerImage, setBannerImage] = useState<any>()
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [toggleSubmit, settoggleSubmit] = useState<any>(false)
  const [isDeactivatedSport, setIsDeactivatedSport] = useState<any>(false)
  const numberRegExp = /^[0-9]\d*$/

  const memberSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    short_description: Yup.string().required('Required'),
    target_amount: Yup.string().matches(
      numberRegExp,
      'Kun tall er tillatt i dette feltet. Bokstaver og andre symboler er ikke tillatt.'
    ),
    start_date: Yup.string().required('Required'),
    // add status to the schema if it exists
    status: initialValues?.status ? Yup.string().required('Required') : Yup.string(),
    goal_type: initialValues?.goal_type ? Yup.string().required('Required') : Yup.string(),
  })

  const navigate = useNavigate()

  const getGoalDetails = async () => {
    const { data } = await handleGetRequest(`/goal/${id}`)(setLoading)
    getOrgDetails(data?.organisation_id)
    setBannerImage(data?.banner_image)
    patchForm(data)
    const currentSport = currentUser?.user?.sports_list?.find(
      (sport: any) => sport._id === data.organisation_sports_category_id
    )
    setIsDeactivatedSport(currentSport?.status === 'disabled')
  }

  const getOrgDetails = async (organisation_id: string) => {
    const { data } = await handleGetRequest(`/organisation/${organisation_id}`)(setLoading)
    setOrganisation(data)
  }

  const getLogo = (): string => {
    if (organisation?.logo) {
      return organisation?.logo
    }
    if (organisation?.org_logo_base64) {
      return `data:image/png;base64,${organisation?.org_logo_base64}`
    }
    return toAbsoluteUrl('/media/misc/logo-missing.jpeg')
  }

  const patchForm = async (data: {
    title: any
    short_description: any
    target_amount: any
    start_date: any
    due_date: any
    status: any
    goal_type: any
    sorting: any
  }) => {
    setStartDate(data?.start_date)
    setEndDate(data?.due_date)

    setInitialValues({
      title: data?.title,
      short_description: data?.short_description,
      target_amount: data?.target_amount,
      start_date: data?.start_date,
      due_date:
        data?.due_date === '3024-01-01T00:00:00.000Z'
          ? ''
          : data?.due_date
            ? moment(data?.due_date).format('YYYY-MM-DD')
            : '',
      status: data?.status,
      goal_type: data?.goal_type,
      sorting: data?.sorting,
    })
  }

  const setDate = (field: any, date: any) => {
    if (field === 'startDate') {
      initialValues.start_date = date
      setStartDate(date)
    }
    if (field === 'dueDate') {
      initialValues.due_date = date
      setEndDate(date)
    }
  }

  const submitStep = async (values: any) => {
    const reFactorValue = `'${values?.target_amount}'`
    values.target_amount = Number(
      reFactorValue?.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, '')
    )

    const body = {
      ...values,
      start_date: startDate,
      due_date: endDate,
    }

    await handlePatchRequest(`/goal/${id}`, body, {})(setLoading)
    if (toggleSubmit) {
      navigate(`/formalSingle/${id}`)
    }
  }

  const getHeaderImage = (): string => {
    return `url(${bannerImage || toAbsoluteUrl('/media/misc/Header-1.jpg')})`
  }

  useEffect(() => {
    getGoalDetails()
  }, [])
  /*   const tooltip = (
    <Tooltip id='tooltip'>
      <p>Vanlig: Støtte som går direkte til det valgte formålet.</p>
      <p>
        Fordeling: Støtte går til klubben som distribuerer støtten som beskrevet i formålet. Merk at
        det er dere som klubb som er ansvarlige for riktig fordeling.
      </p>
    </Tooltip>
  ) */
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'Edit.goal' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <Link to='/org/goals/' className='me-4'>
            <button className='btn btn-primary'>
              {intl.formatMessage({ id: 'goal.overview' })}
            </button>
          </Link>
          {/* <a
              href='#'  
              className='bg-white btn text-muted'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Filter
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 mxC-3' />
            </a> */}
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>30 Days</a>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>90 Days</a>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='modal-body px-12 py-12'>
          <div
            className={`  card card-xl-stretch mb-xl-10 card_borderC
                
                `}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: getHeaderImage(),
              borderRadius: '8px',
            }}
          >
            {/* begin::Body */}
            <div className='p-5 d-flex flex-column'>
              {/* begin::Wrapper */}
              <div
                className='d-flex flex-column flex-grow-1'
                style={{ height: 100, minHeight: 100, width: '100%' }}
              />

              <div className='pt-5'>
                <div className=' d-flex ' style={{ alignItems: 'center' }}>
                  <div
                    className='card bg-white me-3 p-2'
                    style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                  >
                    <img
                      src={toAbsoluteUrl('/media/logos/SlogoNew.jpg')}
                      alt='logo'
                      className=' h-60px logo svg-icon-cufs '
                      style={{ width: '60px', objectFit: 'contain' }}
                    />
                  </div>

                  <div
                    className='card bg-white me-3 p-2'
                    style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                  >
                    <img
                      src={getLogo()}
                      alt='logo'
                      className=' h-60px logo svg-icon-cufs '
                      style={{ width: '60px', objectFit: 'contain' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={submitStep}
            enableReinitialize
            encType='multipart/form-data'
            validationSchema={memberSchema}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className=''>
                  <div>
                    <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'Edit.goal' })}</h3>
                    <div className=' fontsizechfslogtime d-flex align-items-center text-gray-400  me-5 mt-6 '>
                      {intl.formatMessage({ id: 'Use.the.form.below.to.create.a.new.goal' })}
                      <br />
                      {intl.formatMessage({ id: 'Make.a.good.description.of.the.goal' })}
                    </div>
                  </div>
                  <div className='row mb-6 mt-8'>
                    <div className='col-lg-2 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {' '}
                        {intl.formatMessage({ id: 'support.is.for' })}:
                      </label>
                    </div>
                    <div className='col-md-10'>
                      <Field
                        type='text'
                        name='title'
                        className={clsx('form-control form-control-lg mb-3 mb-lg-0', {
                          'is-invalid': touched.title && errors.title,
                        })}
                        placeholder={intl.formatMessage({ id: 'Enter.a.title.for.the.goal' })}
                      />
                      {/* <div className='text-danger'>
                            <ErrorMessage name={`title`} />
                          </div> */}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-lg-2 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {' '}
                        {intl.formatMessage({ id: 'goal.dates' })}:
                      </label>
                    </div>

                    <div className='col-md-10 row pe-0'>
                      <div className='col-md-3'>
                        <Field
                          type='date'
                          name='start_date'
                          dateSy
                          pattern='\d{2}.\d{2}.\d{4}'
                          className={clsx('form-control form-control-lg w-100', {
                            'is-invalid': touched.start_date && errors.start_date,
                          })}
                          placeholder={intl.formatMessage({ id: 'Start.date' })}
                          component={() => (
                            <DatePicker
                              placeholderText={intl.formatMessage({ id: 'Start.date' })}
                              dateFormat='dd.MM.yyyy'
                              calendarStartDay={1}
                              className={clsx('form-control form-control-lg w-100  inputClass', {
                                'is-invalid': touched.start_date && errors.start_date,
                              })}
                              onChange={(date: Date) => {
                                setDate('startDate', moment(date).format('YYYY-MM-DD'))
                                setStartDate(date)
                              }}
                              selected={startDate ? new Date(startDate) : null}
                            />
                          )}
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='start_date' />
                        </div>
                      </div>

                      <div className='col-md-3 pe-0'>
                        <Field
                          type='date'
                          name='due_date'
                          className={clsx('form-control form-control-lg', {
                            'is-invalid': touched.start_date && errors.start_date,
                          })}
                          placeholder={intl.formatMessage({ id: 'End.date' })}
                          pattern='\d{2}.\d{2}.\d{4}'
                          component={() => (
                            <DatePicker
                              placeholderText={intl.formatMessage({ id: 'End.date' })}
                              dateFormat='dd.MM.yyyy'
                              calendarStartDay={1}
                              className='form-control form-control-lg w-100  inputClass'
                              onChange={(date: Date) => {
                                setDate('dueDate', moment(date).format('YYYY-MM-DD'))
                                setEndDate(date)
                              }}
                              selected={endDate ? new Date(endDate) : null}
                            />
                          )}
                        />
                        {/* <div className='text-danger'>
                              <ErrorMessage name={`due_date`} />
                            </div> */}
                      </div>

                      {/*     <div className='col-md-4 pe-0'>
                        <div className='row mb-6'>
                          <div className='col-lg-2'>
                            <label className=' col-form-label fw-bold fs-6'>Type:</label>
                          </div>
                          <div className='col-lg-8'>
                            <Field
                              name='goal_type'
                              className={clsx('form-select form-select-lg fw-bold')}
                              as='select'
                            >
                              <option value='regular'>Vanlig</option>
                              <option value='allocation'>Fordeling</option>
                            </Field>
                          </div>

                          <div className='col-lg-2 d-flex flex-grow-1 justify-content-center align-items-center'>
                            <div className=''>
                              <OverlayTrigger placement='top' overlay={tooltip}>
                                <div>
                                  <i className='far fa-question-circle' />
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-lg-2'>
                      <label className=' col-form-label fw-bold fs-6 '>
                        {' '}
                        {intl.formatMessage({ id: 'Fundraising.goals' })}:
                      </label>
                    </div>
                    <div className='col-md-10'>
                      <Field
                        type='text'
                        name='target_amount'
                        value={values.target_amount}
                        step={1}
                        className={clsx('form-control form-control-lg')}
                        placeholder={intl.formatMessage({
                          id: 'Enter.the.amount.you.want.to.achieve',
                        })}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='target_amount' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-2'>
                    <label className=' col-form-label fw-bold fs-6'>
                      {' '}
                      {intl.formatMessage({ id: 'Description' })}:
                    </label>
                  </div>

                  <div className='col-lg-10'>
                    <CKEditor
                      editor={ClassicEditor}
                      data={initialValues?.short_description || ' '}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        initialValues.short_description = data
                        values.short_description = data
                      }}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'blockQuote',
                          'numberedList',
                          'bulletedList',
                          '|',
                          'undo',
                          'redo',
                        ],
                      }}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-2'>
                    <label className=' col-form-label fw-bold fs-6 text-capitalize'>
                      {intl.formatMessage({ id: 'status.lowercase' })}:
                    </label>
                  </div>
                  <div className='col-lg-10'>
                    <Field
                      name='status'
                      disabled={isDeactivatedSport}
                      className={clsx(
                        'form-select form-select-lg fw-bold',
                        isDeactivatedSport ? 'disabled' : ''
                      )}
                      as='select'
                    >
                      {/* "active", "completed", "paused", "canceled" */}
                      <option value='active'> {intl.formatMessage({ id: 'Active' })}</option>
                      <option value='completed'> {intl.formatMessage({ id: 'Finished' })}</option>
                      <option value='paused'> {intl.formatMessage({ id: 'Pause' })}</option>
                      <option value='canceled'> {intl.formatMessage({ id: 'Cancel' })}</option>
                    </Field>
                    {/* <div className='text-danger'>
                          <ErrorMessage name={`status`} />
                        </div> */}
                    {isDeactivatedSport &&
                      'Enheten er deaktivert og du vil ikke kunne redigere status på formålet'}
                  </div>
                </div>
                <div className='row  mb-3 mt-7'>
                  <div className='col-lg-2'>
                    <label className=' col-form-label fw-bold fs-6 '>
                      {intl.formatMessage({ id: 'sorting' })}:
                    </label>
                  </div>
                  <div className='col-md-10'>
                    <div className='d-flex '>
                      <Field
                        type='number'
                        name='sorting'
                        min={-1}
                        className='form-control form-control-lg form-control-solid inputClass me-2 '
                      />
                    </div>
                    <label className=' col-form-label fw-bold fs-6 '>
                      {intl.formatMessage({ id: 'This.sorting.is.reflected.in.the.app' })}
                    </label>
                  </div>
                </div>

                <div className='col-md-10 offset-md-2 d-flex justify-content-between'>
                  <Link to='/org/goals/'>
                    <button type='button' className='btn btn-sm btn-light' data-bs-dismiss='modal'>
                      {intl.formatMessage({ id: 'Cancel' })}
                    </button>
                  </Link>

                  <div>
                    <button
                      type='submit'
                      className='btn btn-sm btn-primary mx-2'
                      onClick={() => settoggleSubmit(false)}
                    >
                      <span className='indicator-label'>
                        {' '}
                        {intl.formatMessage({ id: 'Save.goal' })}
                      </span>
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      onClick={() => settoggleSubmit(true)}
                    >
                      <span className='indicator-label'>
                        {intl.formatMessage({ id: 'Save.and.close' })}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <GalleryDrawer
        handleSuccess={(image: string | null) => {
          setBannerImage(image)
        }}
      />
    </>
  )
}
