/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Sentry from '@sentry/react'

import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
} from '../../services'
import { StudioUtils } from '../Marketing/studio/StudioUtils'
import CampaignDeletionModal from './modal/CampaignDeletionModal'
import CampaignCreationModal from './modal/creation/CampaignCreationModal'
import { IFacebookPreview } from './modal/creation/CampaignFacebookForm'

type Props = {
  className: string
}

export interface IRecruiter {
  name: string
  short_user_id: string
  email: string
  recruited_users: {
    user_id: string
    total_amount: number
    recruited_on: Date
  }[]
  hasBeenGenerated?: boolean
  b64QRCode?: string
}

export interface ICampaign {
  _id?: string
  parent_campaign_id?: string
  organisation_id?: string
  organisation_sports_category_id?: string
  goal_id?: string
  status: 'active' | 'completed' | 'canceled'
  banner_image?: string
  image?: string
  title: string
  short_description?: string
  start_date?: Date
  end_date?: Date
  recruiters: IRecruiter[]
  created_at: Date
  updated_at: Date
  deleted?: boolean
  deleted_at?: Date
  flyer?: {
    header: string
    subheader: string
  }
  facebook_preview?: IFacebookPreview
}

export interface ICreateCampaign {
  name: string
  description: string
  start_date: Date
  end_date: Date
  level: 'goal' | 'unit' | 'organisation'
  goal?: string
  unit?: string
}

const CampaignTable: React.FC<Props> = ({ className }) => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const [campaigns, setCampaigns] = useState<{
    pagination: { totalRecords: number }
    data: ICampaign[]
  }>()
  const intl = useIntl()
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [showCreateCampaign, setShowCreateCampaign] = useState(false)
  const [showDeleteCampaign, setShowDeleteCampaign] = useState(false)
  const [potentialDeleteCampaign, setPotentialDeleteCampaign] = useState<string | undefined>()

  const [mostRecentCampaign, setMostRecentCampaign] = useState<ICampaign | undefined>()
  const [mostRecentCampaignID, setMostRecentCampaignID] = useState<string | undefined>()
  const [recruiters, setRecruiters] = useState<IRecruiter[]>([])

  const getCampaigns = async () => {
    const params = {
      organisation_id: currentUser?.user?.organisation_id?._id,
      limit: PageSize,
      page: currentPage,
    }
    const res = await handleGetRequest('/campaign/all_campaigns', {
      params,
    })(setLoading)
    setCampaigns(res)
  }

  const getStatus = (status = 'active') => {
    const badgeClass = {
      complete: 'badge-light-warning',
      active: 'badge-light-success',
      canceled: 'badge-light-danger',
    }[status]

    return (
      <span className={`badge status-button badge-sm ${badgeClass}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    )
  }

  const getTotalRecruitedUsers = (recruiters: IRecruiter[]) => {
    return recruiters.reduce((acc, curr) => acc + curr.recruited_users.length, 0)
  }

  const getLevel = (campaign: ICampaign) => {
    if (campaign.goal_id) {
      return 'Goal'
    }
    if (campaign.organisation_sports_category_id) {
      return 'Sports'
    }
    return 'Organisation'
  }

  useEffect(() => {
    getCampaigns()
  }, [PageSize, currentPage, companyofPortal.company_id, currentUser?.user?.organisation_id._id])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column' style={{ maxWidth: '70%' }}>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'overview.campaign' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              Her kan du opprette og administrere kampanjer for å verve nye støttespillere til din
              organisasjon, et formål eller en spesifikk enhet. Legg til deltagere som skal bidra
              til å verve nye støttespillere. Kampanjene gir deg full oversikt og enkel oppfølging
              av fremgangen.
            </span>
          </h3>

          <div className='card-toolbar'>
            <div
              onClick={() => {
                setShowCreateCampaign(true)
              }}
              className='btn btn-lg btn-primary authbgcolor'
            >
              {intl.formatMessage({ id: 'create.campaign' })}
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th className='ps-4 rounded-start'>{intl.formatMessage({ id: 'campaign' })}</th>
                  <th className=''>{intl.formatMessage({ id: 'level' })}</th>
                  {/* 
                  <th className=''>{intl.formatMessage({ id: 'startdate' })}</th>
                  <th className=''>{intl.formatMessage({ id: 'enddate' })}</th> */}
                  <th> Nye støttespillere</th>
                  <th>{intl.formatMessage({ id: 'status' })}</th>
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              <tbody>
                {campaigns?.data?.length ? (
                  campaigns?.data?.map((tbData) => (
                    <tr key={tbData?._id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <Link
                            to={`/campaignSingle/${tbData?._id}`}
                            className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-6 left-text'
                          >
                            {tbData?.title}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {getLevel(tbData)}
                        </a>
                      </td>
                      {/* <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {moment(tbData?.start_date).format('DD.MM.YYYY')}
                        </a>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {moment(tbData?.end_date).format('DD.MM.YYYY')}
                        </a>
                      </td> */}
                      <td className=''>
                        {(tbData?.recruiters && getTotalRecruitedUsers(tbData?.recruiters)) || 0}
                      </td>
                      <td className=''>{tbData?.status && getStatus(tbData?.status)}</td>
                      <td style={{ float: 'right' }}>
                        <div className='d-flex flex-shrink-0'>
                          <a
                            onClick={() => {
                              setShowDeleteCampaign(true)
                              setPotentialDeleteCampaign(tbData._id)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                          >
                            <i className='fa-duotone fa-trash' />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'nothing.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <CustomPagination
                className='pagination-bar justify-content-md-end'
                currentPage={currentPage}
                totalCount={campaigns ? campaigns?.pagination?.totalRecords : 0}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
      <CampaignDeletionModal
        handleDelete={async () => {
          if (potentialDeleteCampaign) {
            await handleDeleteRequest(`/campaign/${potentialDeleteCampaign}`)(setLoading)
            await getCampaigns()
            setPotentialDeleteCampaign(undefined)
          }
          setShowDeleteCampaign(false)
        }}
        setShowModalConfirm={setShowDeleteCampaign}
        showModalConfirm={showDeleteCampaign}
      />
      <CampaignCreationModal
        initialValues={() => {
          return {
            name: '',
            description: '',
            start_date: new Date(),
            end_date: moment().add(2, 'month').toDate(),
            level: 'organisation',
            goal: '',
            unit: '',
          }
        }}
        setShowModal={setShowCreateCampaign}
        showModal={showCreateCampaign}
        mostRecentCampaign={mostRecentCampaign}
        onClose={() => {}}
        recruiters={recruiters}
        submitInfoCallback={async (modalData) => {
          setLoading(true)
          const data = {
            organisation_id: currentUser?.user?.organisation_id?._id,
            organisation_sports_category_id:
              modalData.level !== 'organisation' ? modalData.unit : null,
            goal_id: modalData.level === 'goal' ? modalData.goal : null,
            status: 'active',
            title: modalData.name,
            short_description: modalData.description,
            start_date: modalData.start_date,
            end_date: modalData.end_date,
            flyer: {
              header: StudioUtils.encodeText(modalData.name),
              subheader: StudioUtils.encodeText(
                `Støtt ${currentUser?.user?.organisation_id?.org_name}!`
              ),
            },
          }
          const campaign = await handlePostRequest('/campaign/', data)(setLoading)

          if (!campaign.data) {
            setShowCreateCampaign(false)
            toast.error('Kampanjeopprettelse mislyktes, vennligst ta kontakt med support')
            Sentry.captureMessage('Campaign creation failed', { extra: { campaign } })
          } else {
            await getCampaigns()
            setMostRecentCampaign(campaign.data)
            setMostRecentCampaignID(campaign.data._id)
          }
        }}
        handleUpload={async (recruiters) => {
          if (!mostRecentCampaignID) return
          await handlePostRequest(`/campaign/add_users/`, {
            campaign_id: mostRecentCampaignID,
            recruiters: JSON.stringify(recruiters),
          })(setLoading)
          const campaign = await handleGetRequest(`/campaign/single/${mostRecentCampaignID}`)(
            setLoading
          )
          setMostRecentCampaign(campaign.data)
          setRecruiters(campaign.data.recruiters)
        }}
        campaignId={mostRecentCampaignID}
        handleUploadFlyer={async (doc) => {
          if (!mostRecentCampaignID) return

          const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
            flyer: {
              header: StudioUtils.encodeText(doc.topHeader),
              subheader: StudioUtils.encodeText(doc.bottomHeader),
            },
          })(setLoading)
          setMostRecentCampaign(campaign.data)
        }}
        handleSendtoRecruiters={async (selectedSendRecruiters) => {
          if (selectedSendRecruiters.length > 0) {
            await handlePostRequest(`/organisation_user/send-campaign-email`, {
              campaignId: mostRecentCampaignID,
              recruiter_short_user_ids: selectedSendRecruiters.map((r) => r.short_user_id),
            })(setLoading)
            setLoading(false)
          }
        }}
        handleFacebookPreview={async (facebook_preview) => {
          if (!mostRecentCampaignID) return
          const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
            facebook_preview,
          })(setLoading)
          setMostRecentCampaign(campaign.data)
        }}
      />
    </>
  )
}

export { CampaignTable }
