/* eslint-disable prefer-const */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePostRequest } from '../../services'
import { GalleryDrawer1 } from '../Drawer/GalleryDrawerAddGoal'

export default function FormalAdd() {
  const [initialValues] = useState({
    title: '',
    short_description: '',
    target_amount: '',
    start_date: '',
    due_date: '',
    goal_type: '',
  })
  const numberRegExp = /^[0-9]\d*$/
  const intl = useIntl()
  const memberSchema = Yup.object().shape({
    title: Yup.string().required('Required').max(45, 'Tittelen kan ikke overskride 45 bokstaver'),
    short_description: Yup.string().required('Required'),
    target_amount: Yup.string().matches(
      numberRegExp,
      'Kun tall er tillatt i dette feltet. Bokstaver og andre symboler er ikke tillatt.'
    ),
    start_date: Yup.string().required('Required'),
  })

  const { companyofPortal } = useMainContext()
  const { setLoading } = useContext(LoadingContext)
  const [organisation, setOrganisation] = useState<any>()
  const navigate = useNavigate()
  let [startDate, setStartDate] = useState<any>()
  let [endDate, setEndDate] = useState<any>()
  const { currentUser } = useAuth()
  const [banner, setBanner] = useState<string>('')

  const getOrgDetails = async (organisation_id: string) => {
    const { data } = await handleGetRequest(`/organisation/${organisation_id}`)(setLoading)
    setOrganisation(data)
  }

  useEffect(() => {
    getOrgDetails(currentUser?.user?.organisation_id?._id)
    const handleStorageChange = () => {
      if (localStorage.getItem('banner')) {
        setBanner(localStorage.getItem('banner') || '')
      } else {
        setBanner('')
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      localStorage.removeItem('banner')
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const getLogo = (): string => {
    if (organisation?.logo) {
      return organisation?.logo
    }
    if (organisation?.org_logo_base64) {
      return `data:image/png;base64,${organisation?.org_logo_base64}`
    }
    return toAbsoluteUrl('/media/misc/logo-missing.jpeg')
  }

  const setDate = (field: any, date: any) => {
    if (field === 'startDate') {
      initialValues.start_date = date
      startDate = date
    }
    if (field === 'dueDate') {
      initialValues.due_date = date
      endDate = date
    }
  }

  const submitStep = async (values: any) => {
    const reFactorValue = `'${values?.target_amount}'`
    values.target_amount = Number(
      reFactorValue?.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, '')
    )

    const body = {
      ...values,
      start_date: startDate,
      due_date: endDate,
      organisation_id: currentUser?.user?.organisation_id?._id,
      organisation_sports_category_id: companyofPortal?.company_id,
      short_description: initialValues?.short_description || values?.short_description,
      banner_image: banner || '',
      goal_type: values.goal_type || 'regular',
    }

    const response = await handlePostRequest(`/goal`, body, {})(setLoading)
    navigate(
      companyofPortal?.company_id === null ? 'dashboard' : `/formalSingle/${response?.data?._id}`
    )
  }

  const getHeaderImage = (): string => {
    return `url(${banner || toAbsoluteUrl('/media/misc/Header-1.jpg')})`
  }

  /*   const tooltip = (
    <Tooltip id='tooltip'>
      <p>Vanlig: Støtte som går direkte til det valgte formålet.</p>
      <p>
        Fordeling: Støtte går til klubben som distribuerer støtten som beskrevet i formålet. Merk at
        det er dere som klubb som er ansvarlige for riktig fordeling.
      </p>
    </Tooltip>
  ) */
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'Create.new.goal' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <Link to='/org/goals/' className='me-4'>
            <button className='btn btn-primary'>
              {intl.formatMessage({ id: 'goal.overview' })}
            </button>
          </Link>
        </div>
      </div>
      <div className='card mb-5 mb-xl-8'>
        <div className='modal-body px-12 py-12'>
          <div
            className={`  card card-xl-stretch mb-xl-10 card_borderC
                
                `}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: getHeaderImage(),
              borderRadius: '8px',
            }}
          >
            {/* begin::Body */}
            <div className='p-5 d-flex flex-column'>
              {/* begin::Wrapper */}
              <div
                className='d-flex flex-column flex-grow-1'
                style={{ height: 100, minHeight: 100, width: '100%' }}
              />

              <div className='pt-5'>
                <div className=' d-flex ' style={{ alignItems: 'center' }}>
                  <div
                    className='card bg-white me-3 p-2'
                    style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                  >
                    <img
                      src={toAbsoluteUrl('/media/logos/SlogoNew.jpg')}
                      alt='logo'
                      className=' h-60px logo svg-icon-cufs '
                      style={{ width: '60px', objectFit: 'contain' }}
                    />
                  </div>

                  <div
                    className='card bg-white me-3 p-2'
                    style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                  >
                    <img
                      src={getLogo()}
                      alt='logo'
                      className=' h-60px logo svg-icon-cufs '
                      style={{ width: '60px', objectFit: 'contain' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={submitStep}
            enableReinitialize
            // encType='multipart/form-data'
            validationSchema={memberSchema}
            // onSubmit={(values, actions) => {
            //   if (banner) submitStep({...values, banner_image: banner}, actions.setFieldError)
            // }}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <div className=''>
                  <div>
                    <h3 className='fw-bolder my-2'>
                      {intl.formatMessage({ id: 'Create.new.goal' })}
                    </h3>
                    <div className=' fontsizechfslogtime d-flex align-items-center text-gray-400  me-5 mt-6 '>
                      {intl.formatMessage({ id: 'Use.the.form.below.to.create.a.new.goal' })}
                      <br />
                      {intl.formatMessage({ id: 'Make.a.good.description.of.the.goal' })}
                    </div>
                  </div>
                  <div className='row mb-6 mt-8'>
                    <div className='col-lg-2 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({ id: 'support.is.for' })}:
                      </label>
                    </div>
                    <div className='col-md-10'>
                      <Field
                        type='text'
                        name='title'
                        className={clsx('form-control form-control-lg mb-3 mb-lg-0', {
                          'is-invalid': touched.title && errors.title,
                        })}
                        placeholder={intl.formatMessage({ id: 'Enter.a.title.for.the.goal' })}
                      />

                      <div className='text-danger'>
                        <ErrorMessage name='title' />
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-lg-2 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({ id: 'goal.dates' })}:
                      </label>
                    </div>

                    <div className='col-md-10 row pe-0'>
                      <div className='col-md-3'>
                        <Field
                          type='date'
                          name='start_date'
                          dateSy
                          pattern='\d{2}.\d{2}.\d{4}'
                          className='form-control form-control-lg form-control-solid inputClass'
                          placeholder={intl.formatMessage({ id: 'Start.date' })}
                          component={() => (
                            <DatePicker
                              placeholderText={intl.formatMessage({ id: 'Start.date' })}
                              dateFormat='dd.MM.yyyy'
                              calendarStartDay={1}
                              className={clsx(
                                'form-control form-control-lg form-control-solid inputClass',
                                {
                                  'is-invalid': touched.start_date && errors.start_date,
                                }
                              )}
                              onChange={(date: Date) => {
                                setDate('startDate', moment(date).format('YYYY-MM-DD'))
                                setStartDate(date)
                                setFieldValue('start_date', moment(date).format('YYYY-MM-DD'))
                              }}
                              selected={startDate ? new Date(startDate) : null}
                            />
                          )}
                        />
                        {/* <div className='text-danger'>
                            <ErrorMessage name={`start_date`} />
                          </div> */}
                      </div>

                      <div className='col-md-3 pe-0'>
                        <Field
                          type='date'
                          name='due_date'
                          className='form-control form-control-lg form-control-solid inputClass'
                          placeholder={intl.formatMessage({ id: 'End.date' })}
                          pattern='\d{2}.\d{2}.\d{4}'
                          component={() => (
                            <DatePicker
                              placeholderText={intl.formatMessage({ id: 'End.date' })}
                              dateFormat='dd.MM.yyyy'
                              calendarStartDay={1}
                              className='form-control form-control-lg form-control-solid inputClass'
                              onChange={(date: Date) => {
                                setDate('dueDate', moment(date).format('YYYY-MM-DD'))
                                values.due_date = moment(date).format('YYYY-MM-DD')
                                setEndDate(date)
                                setFieldValue('due_date', moment(date).format('YYYY-MM-DD'))
                              }}
                              selected={endDate ? new Date(endDate) : null}
                            />
                          )}
                        />
                        {/* <div className='text-danger'>
                              <ErrorMessage name={`due_date`} />
                            </div> */}
                      </div>
                      {/*          <div className='col-md-4 pe-0'>
                        <div className='row mb-6'>
                          <div className='col-lg-2'>
                            <label className=' col-form-label fw-bold fs-6'>Type:</label>
                          </div>
                          <div className='col-lg-8'>
                            <Field
                              name='goal_type'
                              value={values.goal_type}
                              className={clsx('form-select form-select-lg fw-bold')}
                              as='select'
                            >
                              <option value='regular'>Vanlig</option>
                              <option value='allocation'>Fordeling</option>
                            </Field>
                          </div>
                          <div className='col-lg-2 d-flex flex-grow-1 justify-content-center align-items-center'>
                            <div className=''>
                              <OverlayTrigger placement='top' overlay={tooltip}>
                                <i className='far fa-question-circle' />
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <div className='col-lg-2'>
                      <label className=' col-form-label fw-bold fs-6 '>
                        {intl.formatMessage({ id: 'Fundraising.goals' })}:
                      </label>
                    </div>
                    <div className='col-md-10'>
                      <Field
                        type='text'
                        name='target_amount'
                        value={values.target_amount}
                        step={1}
                        className={clsx('form-control form-control-lg')}
                        placeholder={intl.formatMessage({
                          id: 'Enter.the.amount.you.want.to.achieve',
                        })}
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='target_amount' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-2'>
                    <label className=' col-form-label fw-bold fs-6'>
                      {intl.formatMessage({ id: 'Description' })}:
                    </label>
                  </div>

                  <div className='col-lg-10'>
                    <CKEditor
                      editor={ClassicEditor}
                      data={initialValues?.short_description || ' '}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        initialValues.short_description = data
                        values.short_description = data
                      }}
                      config={{
                        toolbar: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          'blockQuote',
                          'numberedList',
                          'bulletedList',
                          '|',
                          'undo',
                          'redo',
                        ],
                      }}
                    />
                  </div>
                </div>

                <div className='col-md-10 offset-md-2 d-flex justify-content-between'>
                  <Link to='/org/goals/'>
                    <button type='button' className='btn btn-sm btn-light' data-bs-dismiss='modal'>
                      {intl.formatMessage({ id: 'Cancel' })}
                    </button>
                  </Link>

                  <button type='submit' className='btn btn-sm btn-primary'>
                    <span className='indicator-label'>
                      {intl.formatMessage({ id: 'Save.goal' })}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <GalleryDrawer1 />
    </>
  )
}
